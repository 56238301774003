@import url(https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap);

body {
    background-color: #69849b;
    font-family: 'Noto Sans', sans-serif;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

button {
    padding: 5px;
}

.countdown-container {
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
    text-align: left;
}
.countdown {
    position: relative;
}

.countdown h1 {
    cursor: text;
    margin: 0;
    padding: 0;
}

.countdown h2 {
    color: grey;
    margin: 0;
    padding: 0;
}

.countdown-delete {
    border: none;
    background: transparent;
    color: grey;
    position: absolute;
    top: -7px;
    right: -7px;
    cursor: pointer;
}

.date-button {
    cursor: default;
    padding: 5px 10px;
    margin-top: 8px;
}
