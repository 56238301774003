@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

body {
    background-color: #69849b;
    font-family: 'Noto Sans', sans-serif;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

button {
    padding: 5px;
}

.countdown-container {
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
    text-align: left;
}